import React from "react";
import "../css/MobileHamburgerMenu.css"
import {LEARNING_PATHS} from "../constants/LearningPathsConfig";
import {GoPremiumButton, LogInButton} from "./GoPremiumButton";
import {ICONS} from "../util/IconHelper";
import {useNavigate} from "react-router-dom";
import {EVENTS, Page, trackEvent, TrackingEvent} from "../util/TrackingHelper";

export const MobileHamburgerMenu:React.FC<any> = ({setShowHamburger, isAuthenticated, isPremium, setShowAuth, setShowPremiumOverlay}) => {
    const navigate = useNavigate()

    const onHamburgerMenuClick = (navUrl: string, trackingEvent: TrackingEvent) => {
        navigate(navUrl)
        trackEvent(trackingEvent)
    }
    return (
        <div className={'mobile-hamburger-container'} onClick={() => setShowHamburger(false)}>
            <div className={'mobile-hamburger-content'}>
                <ICONS.CLOSE className={'hamburger-close-icon'} />
                <div className={'hamburger-options'}>
                    <div className={'hamburger-log-in-and-join-container'}>
                        {!isAuthenticated && <LogInButton />}
                        {!isPremium && <GoPremiumButton isHeader={true} />}
                    </div>
                </div>
            </div>
        </div>
    )
}