import React from 'react';
import '../css/HeroImage.css';
import {GoPremiumButton} from "./GoPremiumButton";
import HeroImage1 from "../images/HeroImagePeople.png"
import DOM_AVATAR from "../images/testimonials/Dominik-Jasarevic-Avatar.webp"
import KAZ_AVATAR from "../images/testimonials/Shane-K-Avatar.webp"
import RYAN_AVATAR from "../images/testimonials/RyanTAvatar.webp"
import {Page, Section} from "../util/TrackingHelper";
import { IoPeopleCircle } from "react-icons/io5";
import {FaStar} from "react-icons/fa";
import {DisplayBars} from "./DisplayBars";

export const HeroImage: React.FC<any> = ({isPremium, isAuthenticated, setShowAuth, setShowPremiumOverlay}) => {
    return (
        <div className="hero-image-container">
            <DisplayBars position={'top'}/>
            <DisplayBars position={'bottom'}/>
            <div className="hero-text-container">
                <h1>Helping You Build Your</h1>
                <div className="hero-highlighted-text">
                    <span className="highlighted">Coding</span>
                    <span> Career</span>
                </div>
                <p>Beyond Code is the #1 place to build a tech career. Whether you’re on day 0, or you’ve programmed a
                    bit, we are here to help you get that career started</p>
                {!isPremium && <GoPremiumButton isAuthenticated={isAuthenticated}
                                                setShowAuth={setShowAuth}
                                                setShowPremiumOverlay={setShowPremiumOverlay}
                                                page={Page.LANDING}
                                                section={Section.HERO}/>}
                <HeroReviews/>
            </div>
            {/*<div className="hero-images-container">*/}
            {/*    <div className="hero-images-container-blue-bar"></div>*/}
            {/*    <img src={HeroImage1} alt={''}/>*/}
            {/*</div>*/}
        </div>
    );
};

export const HeroReviews = ({}) => {
    return (
        <div className={'hero-review-container'}>
        {Array(5).fill(null).map((_, i) => (
                <FaStar key={i} className="testimonial-star-icon"/>
            ))}
            <img id={'hero-testimonial-1'} src={KAZ_AVATAR}/>
            <img id={'hero-testimonial-2'} src={DOM_AVATAR}/>
            <img id={'hero-testimonial-3'} src={RYAN_AVATAR}/>
            {/*<IoPeopleCircle id={'hero-testimonial-more'} />*/}
        </div>
    )
}