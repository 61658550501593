import React from "react";
import "../css/AboutMeModule.css";
import {ABOUT_ME} from "../constants/AboutMeConfig";

export const AboutMeModule = () => {
    return (
        <div className={"about-me-container"}>
            <div className="about-me-module-container">
                <div className="about-me-top-container">
                    <div className="about-me-text-container">
                        <h3 className="about-me-header">Hey! <span className={'blue-text'}>I'm David</span></h3>
                        <p className="about-me-paragraph">{ABOUT_ME.paragraph}</p>
                        <div className="about-me-experience-container">
                            <h4>My Experience Includes:</h4>
                            <div className="why-listen-to-me-items">
                                {ABOUT_ME.whyListenList.map((item, index) => (
                                    <div className="why-listen-item-w-date" key={index}>
                                        <div className="why-listen-item">
                                            <img src={item.image} alt={`${item.place}`}/>
                                            <h3>{item.place}</h3>
                                            <p>{item.position}</p>
                                            {/*<ul>*/}
                                            {/*    {item.items.map((point, index) => (*/}
                                            {/*        <li key={index}>{point}</li>*/}
                                            {/*    ))}*/}
                                            {/*</ul>*/}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <img src={ABOUT_ME.image} alt={"About Me"}/>
                </div>
                {/*<div className="why-listen-to-me-container">*/}
                {/*    <h3>Why Listen to Me?</h3>*/}
                {/*    <div className="why-listen-to-me-items">*/}
                {/*        {ABOUT_ME.whyListenList.map((item, index) => (*/}
                {/*            <div className="why-listen-item-w-date" key={index}>*/}
                {/*                <div className="why-listen-item">*/}
                {/*                    <img src={item.image} alt={`${item.place}`}/>*/}
                {/*                    <h3>{item.place}</h3>*/}
                {/*                    <p>{item.position}</p>*/}
                {/*                    <ul>*/}
                {/*                        {item.items.map((point, index) => (*/}
                {/*                            <li key={index}>{point}</li>*/}
                {/*                        ))}*/}
                {/*                    </ul>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        ))}*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>
        </div>
    );
};