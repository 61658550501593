import React from "react";
import {GoPremiumButton} from "./GoPremiumButton";
import {Page, Section} from "../util/TrackingHelper";
import {DisplayBars} from "./DisplayBars";
import "../css/FinalCTA.css"

export const FinalCTA: React.FC<any> = ({isPremium, isAuthenticated, setShowAuth, setShowPremiumOverlay}) => {
    return (
        <div className={`final-cta-container`}>
            <DisplayBars position={'top'}/>
            <DisplayBars position={'bottom'}/>
            <h4>Your tech Career Awaits...<br/>What Do you have to lose?</h4>
            <div className={'premium-button-container'}>
                {!isPremium && <GoPremiumButton isAuthenticated={isAuthenticated}
                                                setShowAuth={setShowAuth}
                                                setShowPremiumOverlay={setShowPremiumOverlay}
                                                page={Page.LANDING}
                                                section={Section.REVIEWS}/>}
            </div>
        </div>
    )
}