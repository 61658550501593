import {analytics} from "./Firebase";
import {logEvent} from "firebase/analytics";
import {TECH_CONCEPTS_COURSES} from "../constants/CoursesConfig";

export enum EventType {
    CLICK = 'click',
    VIEW = 'view',
}

export enum TRACKING_EVENTS {
    //CTAS
    JOIN_NOW = 'JoinNow',
    GO_PREMIUM = 'GoPremium',
    LOG_IN = 'LogIn',
    USER_SIGN_UP = 'UserSignUp',
    USER_SIGN_IN = 'UserSignIn',
    PREMIUM_CHECKOUT_SESSION = 'PremiumCheckoutSession',
}

export interface TrackingEvent {
    page: string;
    section?: string;
    event: string;
    type?: EventType;
    params?: {
        [key: string]: any;
    };
}

export enum Page {
    HEADER = 'Header',
    MOBILE_HEADER = 'MobileHeader',
    SIGN_UP_POPUP = 'SignUpPopup',
    LANDING = 'Landing',
    BECOMING_A_PROGRAMMER = 'LearningToCode',
    TECH_CONCEPTS = "TechConcepts",
    PROGRAMMING_RESUMES = 'ProgrammingResumes',
    TECH_INTERVIEW_PREP = 'TechInterviewPrep',
}

export enum Section {
    HERO = 'Hero',
    LEARNING_PATH = 'LearningPath',
    MEMBERSHIP_OVERVIEW = 'MembershipOverview',
    REVIEWS = 'Reviews'
}

export const EVENTS = {
    HEADER: {
        HEADER_LOGO: {
            page: Page.HEADER,
            event: 'Logo',
            type: EventType.CLICK
        } as TrackingEvent,
        HEADER_HOME: {
            page: Page.HEADER,
            event: 'Home',
            type: EventType.CLICK
        } as TrackingEvent,
        HEADER_ACCOUNT: {
            page: Page.HEADER,
            event: 'Account',
            type: EventType.CLICK
        } as TrackingEvent,
        BECOMING_PROGRAMMER: {
            page: Page.HEADER,
            event: 'BecomingAProgrammer',
            type: EventType.CLICK
        } as TrackingEvent,
        PROGRAMMER_RESUMES: {
            page: Page.HEADER,
            event: 'ProgrammerResumes',
            type: EventType.CLICK
        } as TrackingEvent,
        TECH_INTERVIEW_PREP: {
            page: Page.HEADER,
            event: 'TechInterviewPrep',
            type: EventType.CLICK
        } as TrackingEvent
    },
    SIGN_UP_POPUP: {
        SIGN_IN_WITH_GOOGLE: {
            page: Page.SIGN_UP_POPUP,
            event: 'Sign_In_With_Google',
            type: EventType.CLICK
        } as TrackingEvent,
        SIGN_IN_WITH_EMAIL: {
            page: Page.SIGN_UP_POPUP,
            event: 'Sign_In_With_Email',
            type: EventType.CLICK
        } as TrackingEvent,
    },
    LANDING: {
        BECOMING_PROGRAMMER_SECTION: {
            page: Page.LANDING,
            event: 'BecomingAProgrammerSection',
            type: EventType.CLICK,
            section: Section.LEARNING_PATH
        } as TrackingEvent,
        PROGRAMMER_RESUMES_SECTION: {
            page: Page.LANDING,
            event: 'ProgrammerResumesSection',
            type: EventType.CLICK,
            section: Section.LEARNING_PATH
        } as TrackingEvent,
        TECH_INTERVIEW_PREP_SECTION: {
            page: Page.LANDING,
            event: 'TechInterviewPrepSection',
            type: EventType.CLICK,
            section: Section.LEARNING_PATH
        } as TrackingEvent,
    }
};

export function trackEvent(eventObj: TrackingEvent) {
    if (analytics) {
        const { page, section, event, type = EventType.CLICK, params = {} } = eventObj;

        const eventParams = {
            page,
            section,
            event_type: type,
            ...params,
        };

        const eventName = `${page}_${event}_${type}`;
        // console.log(`${eventName} - ${eventParams}`)
        logEvent(analytics, eventName, eventParams);
    }
}