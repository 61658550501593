import React from "react";
import "../css/DisplayBars.css";

export const DisplayBars: React.FC<any> = ({position}) => {
    return (
        <div className={`hero-bars-container ${position}`}>
            {position === 'top' && <>
                <div className="bar blue"></div>
                <div className="bar green"></div>
                <div className="bar orange"></div>
            </>}
            {position === 'bottom' && <>
                <div className="bar orange"></div>
                <div className="bar green"></div>
                <div className="bar blue"></div>
            </>}
        </div>
    )
}