import AboutMeAvatar from "../images/Avatar Blue Background.webp"
import LinkedInImage from "../images/aboutMe/LinkedIn-Logo.webp"
import HomeDepotImage from "../images/aboutMe/Home-Depot-Logo.webp"
import DeltaImage from "../images/aboutMe/Delta-Logo.webp"
import FinTechImage from "../images/aboutMe/Brightwell-logo.webp"
import DoDImage from "../images/aboutMe/Department-of-Defense-Logo.webp"

export const ABOUT_ME = {
    "paragraph": "I’ve been a programmer for 10 years, and I have a passion for helping people with their coding careers.\n" +
        "\n" +
        "I’ve worked all over the industry from small start ups to Big Tech. My goal with Beyond Code is to simply teach you everything I wish I had known earlier in my career.",
    "image": AboutMeAvatar,
    "whyListenList": [
        {
            "place": "LinkedIn",
            "position": "Senior Software Engineer",
            "items": [
                "Actively working on a team to help people search for jobs",
                "Created features to help candidates without degrees land interviews",
                "After giving 50+ interviews, I was asked to design the interview prep material for incoming candidates"
            ],
            "date": "2021 - Present",
            "image": LinkedInImage
        },
        {
            "place": "The Home Depot",
            "position": "Senior Software Engineer - Contractor",
            "items": [
                "In response to COVID, worked with a small team that built and maintained Home Depot’s curbside application that allowed employees to safely help customers and which helped generate over $500,000,000 in revenue"
            ],
            "date": "2020",
            "image": HomeDepotImage
        },
        {
            "place": "Delta Airlines",
            "position": "Senior Software Engineer - Contractor",
            "items": [
                "Worked on an iOS and Android app that allowed over 30,000 monthly Delta workers (including pilots, flight attendants, flight control, etc) to message one another and maintain constant collaboration to successfully fly aircrafts"
            ],
            "date": "2019",
            "image": DeltaImage
        },
        {
            "place": "Small Fintech Company",
            "position": "Lead Android Developer",
            "items": [
                "Sole Android developer working on an app that provided payment solutions for over 80,000 cruise line migrant workers"
            ],
            "date": "2018 - 2019",
            "image": FinTechImage
        },
        {
            "place": "US Military",
            "position": "App Developer - Contractor",
            "items": [
                "App developer for a small team that provided ways for active military members to send photos/videos/etc back home to their families"
            ],
            "date": "2016 - 2018",
            "image": DoDImage
        }
    ]
}