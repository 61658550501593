import {LEARNING_TO_CODE_COURSES, PROGRAMMER_RESUMES_COURSES, TECH_CONCEPTS_COURSES, TECH_INTERVIEW_PREP_COURSES} from "./CoursesConfig";
import {INTERVIEWS} from "./InterviewsConfig";
import {RESOURCES} from "./ResourcesConfig";

export const PREMIUM_PERKS = [
    {
        "number":  LEARNING_TO_CODE_COURSES.length + TECH_CONCEPTS_COURSES.length + PROGRAMMER_RESUMES_COURSES.length + TECH_INTERVIEW_PREP_COURSES.length,
        "text": "Courses",
        "subtitle": "Access all of our high quality courses that will help you build your career"
    },
    {
        "number": INTERVIEWS.techInterviewPrep.length + INTERVIEWS.programmerResumes.length + INTERVIEWS.becomingAProgrammer.length,
        "text": "Interviews",
        "subtitle": "Enjoy exclusive interviews with industry leaders that will help you navigate this field"
    },
    {
        "number": RESOURCES.becomingAProgrammer.length + RESOURCES.programmerResumes.length,
        "text": "Resources",
        "subtitle": "Our downloadable resources are all designed to make your journey easier"
    },
    {
        "number": null,
        "text": "Community",
        "subtitle": "Ask questions, meet new friends, and grow your career as a member of our friendly community"
    }
]