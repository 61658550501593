import ProgrammingResumesIcon from "../images/Programming Resumes and More Icon.webp";
import ProgrammingResumesHero from "../images/learningPaths/ProgrammingResumeHero.webp";
import TechInterviewPrepIcon from "../images/Tech Interview Icon.webp";
import TechInterviewPrepHero from "../images/learningPaths/TechInterviewPrepHero.webp";
import LearningToCodeIcon from "../images/Learning to Code Icon.webp";
import LearningToCodeHero from "../images/learningPaths/LearningToCodeHero.webp";
import {
    TECH_CONCEPTS_COURSES,
    LEARNING_TO_CODE_COURSES,
    PROGRAMMER_RESUMES_COURSES,
    TECH_INTERVIEW_PREP_COURSES
} from "./CoursesConfig";
import {ALL_INTERVIEWS, INTERVIEWS} from "./InterviewsConfig";
import {ALL_RESOURCES, RESOURCES} from "./ResourcesConfig";
import {ARTICLES} from "./ArticlesConfig";
import {ADVICE} from "./AdviceConfig";
import {EVENTS, Page} from "../util/TrackingHelper";

export const ALL_CONTENT = {
    allCourses: [
        {
            header: "Learning to Code",
            navUrl: "/learning-to-code/",
            pageTracking: Page.BECOMING_A_PROGRAMMER,
            content: LEARNING_TO_CODE_COURSES
        },
        {
            header: "Tech Concepts",
            navUrl: "/tech-concepts/",
            pageTracking: Page.TECH_CONCEPTS,
            content: TECH_CONCEPTS_COURSES
        },
        {
            header: "Job Searching",
            navUrl: "/job-searching/",
            pageTracking: Page.PROGRAMMING_RESUMES,
            content: PROGRAMMER_RESUMES_COURSES
        },
        {
            header: "Interview Prep",
            navUrl: "/tech-interview-prep/",
            pageTracking: Page.TECH_INTERVIEW_PREP,
            content: TECH_INTERVIEW_PREP_COURSES
        },
    ],
    allInterviews: [
        {
            header: "Formal Interviews",
            navUrl: "/formal-interviews/",
            content: ALL_INTERVIEWS.FORMAL_INTERVIEWS
        },
        {
            header: "Mock Interviews",
            navUrl: "/mock-interviews/",
            content: ALL_INTERVIEWS.MOCK_INTERVIEWS
        }
    ],
    allResources: [
        {
            header: "Guides",
            navUrl: "/guides/",
            content: ALL_RESOURCES.GUIDES
        },
        {
            header: "Cheat Sheets",
            navUrl: "/cheat-sheets/",
            content: ALL_RESOURCES.CHEAT_SHEETS
        },
        {
            header: "Resume Examples",
            navUrl: "/resume-examples/",
            content: ALL_RESOURCES.RESUME_EXAMPLES
        }
    ]
}

export const LEARNING_PATHS = [
    {
        header: "Becoming A Programmer",
        subtitle: "First things first: Let's learn what programming is and, more importantly, if you should become a programmer...",
        navUrl: "/learning-to-code/",
        icon: LearningToCodeIcon,
        pageTracking: Page.BECOMING_A_PROGRAMMER,
        heroImage: LearningToCodeHero,
        cssId: 'learning-to-code',
        pageHeader: 'Helping You',
        pageHeaderSpan: 'Become A Programmer',
        headerClickEvent: EVENTS.HEADER.BECOMING_PROGRAMMER,
        sectionClickEvent: EVENTS.LANDING.BECOMING_PROGRAMMER_SECTION,
        courseHeader: 'Courses for Becoming a Programmer',
        courses: LEARNING_TO_CODE_COURSES,
        interviewHeader: 'Interviews from Software Engineers',
        interviews: INTERVIEWS.becomingAProgrammer,
        resourceHeader: 'Resources for New Developers',
        resources: RESOURCES.becomingAProgrammer,
        adviceHeader: 'Expert Advice on Learning to Code',
        advice: ADVICE.becomingAProgrammer,
        articlesHeader: 'Articles',
        articles: ARTICLES.becomingAProgrammer
    },
    {
        header: "Programmer Resumes And More",
        subtitle: "About to start your job search? Learn how to stand out as an applicant and line up interviews",
        navUrl: "/write-a-tech-resume-and-more/",
        icon: ProgrammingResumesIcon,
        pageTracking: Page.PROGRAMMING_RESUMES,
        heroImage: ProgrammingResumesHero,
        cssId: 'programming-resumes',
        pageHeader: 'Helping You Write Your',
        pageHeaderSpan: 'Tech Resume, Cover Letter, & More',
        headerClickEvent: EVENTS.HEADER.PROGRAMMER_RESUMES,
        sectionClickEvent: EVENTS.LANDING.PROGRAMMER_RESUMES_SECTION,
        courseHeader: 'Resume Building Courses',
        courses: PROGRAMMER_RESUMES_COURSES,
        interviewHeader: 'Interviews About Job Searching',
        interviews: INTERVIEWS.programmerResumes,
        resourceHeader: 'Resources for Writing A Programmer Resume',
        resources: RESOURCES.programmerResumes,
        adviceHeader: 'Expert Advice on Tech Resumes',
        advice: ADVICE.techInterviews,
        articlesHeader: 'Articles',
        articles: ARTICLES.programmerResumes
    },
    {
        header: "Tech Interview Prep",
        subtitle: "Tech interviews can be tricky, but they don't have to be. Learn the best way to prepare and land that job offer",
        navUrl: "/tech-interview-prep/",
        icon: TechInterviewPrepIcon,
        pageTracking: Page.TECH_INTERVIEW_PREP,
        heroImage: TechInterviewPrepHero,
        cssId: 'tech-interview-prep',
        pageHeader: 'Helping You Prepare For',
        pageHeaderSpan: 'Tech Interviews',
        headerClickEvent: EVENTS.HEADER.TECH_INTERVIEW_PREP,
        sectionClickEvent: EVENTS.LANDING.TECH_INTERVIEW_PREP_SECTION,
        courseHeader: 'Courses for Tech Interviews',
        courses: TECH_INTERVIEW_PREP_COURSES,
        interviewHeader: 'Mock Programmer Interviews',
        interviews: INTERVIEWS.techInterviewPrep,
        resourceHeader: 'Resources to Ace Your Interview',
        resources: RESOURCES.programmerResumes,
        adviceHeader: 'Interviewing Advice From Experts',
        advice: ADVICE.techInterviews,
        articlesHeader: 'Articles',
        articles: ARTICLES.programmerResumes
    }
]