// Firebase.tsx
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getStorage } from 'firebase/storage';
import { getFirestore, doc, setDoc } from 'firebase/firestore';
import React, {useEffect, useState} from "react";
import { GoogleAuthProvider, EmailAuthProvider, getAuth, UserCredential, createUserWithEmailAndPassword } from "firebase/auth";
import GoogleAuthButton from "../components/GoogleAuthButton";
import * as firebaseui from 'firebaseui';
import 'firebaseui/dist/firebaseui.css';
import "../css/Firebase.css";
import LogoMedium from "../images/Logo_Medium.webp";
import { ICONS } from "./IconHelper";
import {Page, trackEvent, TRACKING_EVENTS} from "./TrackingHelper";
import {useNavigate} from "react-router-dom";
import {FOOTER_CONFIG} from "../constants/FooterConfig";

const firebaseConfig = {
    apiKey: "AIzaSyBqeFOCVZfmfOqM0aMFsESYuKUpBCLVkLY",
    authDomain: "beyondcode-70c30.firebaseapp.com",
    projectId: "beyondcode-70c30",
    storageBucket: "beyondcode-70c30.appspot.com",
    messagingSenderId: "751029928365",
    appId: "1:751029928365:web:9df79853d432cabcb32124",
    measurementId: "G-KL9MF81ZML"
};

export const firebaseApp = initializeApp(firebaseConfig);
export const analytics = (process.env.REACT_APP_ENV === 'production') ? getAnalytics(firebaseApp) : null;
export const storage = getStorage(firebaseApp);
export const auth = getAuth(firebaseApp);
export const firestore = getFirestore(firebaseApp);

interface FirebaseAuthProps {
    setShowAuth: (show: boolean) => void;
}

export const FirebaseAuth: React.FC<FirebaseAuthProps> = ({ setShowAuth }) => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState<string | null>(null);
    const navigate = useNavigate();
    const handleGoogleSuccess = async (user: any) => {
        try {
            await setDoc(doc(firestore, "users", user.uid), {
                uid: user.uid,
                email: user.email,
                name: user.displayName || "Anonymous",
                provider: "google",
                photoUrl: user.photoURL || null,
            }, { merge: true });
        } catch (error) {
            console.error("Error saving user data: ", error);
        }
        navigate("/");
    };

    const handleGoogleError = (error: any) => {
        console.error("Google sign-in error:", error);
    };

    const handleSignUp = async (e: React.FormEvent) => {
        e.preventDefault();
        setError(null);

        const auth = getAuth();

        try {
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;

            await setDoc(doc(firestore, "users", user.uid), {
                uid: user.uid,
                email: user.email,
                name: user.displayName || "Anonymous",
                provider: "email",
            });

            navigate("/");
        } catch (error: any) {
            console.error("Error during email/password sign-up: ", error);
            setError(error.message);
        }
    };
    const header = "Almost there!" // = isLogin ? "Welcome Back!" : "Almost there!";

    return (
        <div className={"firebase-auth-background"} onClick={() => setShowAuth(false)}>
            <div className={"firebase-auth-modal"} onClick={(e) => e.stopPropagation()}>
                <ICONS.CLOSE className="auth-container-close" onClick={() => setShowAuth(false)}/>
                <img className={"header-logo"} src={LogoMedium} alt={"Beyond Code Logo"}/>
                <div className="form-container">
                    <GoogleAuthButton onSuccess={handleGoogleSuccess} onError={handleGoogleError}/>
                    <div className="divider">or</div>
                    <form onSubmit={handleSignUp}>
                        <input type="email" placeholder="Enter your email" className="input-field" value={email}
                               onChange={(e) => setEmail(e.target.value)} required/>
                        <input type="password" placeholder="Enter your password" className="input-field"
                               value={password}
                               onChange={(e) => setPassword(e.target.value)} required/>
                        <button type="submit" className="submit-button">Build My Tech Career</button>
                        {error && <p className="error-message">{error}</p>}
                    </form>
                    <p className="terms">
                        By continuing to register, you agree to our <br/><a href={FOOTER_CONFIG.privacyUrl}>Privacy Policy</a> and <a
                        href={FOOTER_CONFIG.termsUrl}>Terms of Use</a>.
                    </p>
                </div>

            </div>
        </div>
    )
        ;
};

export default FirebaseAuth;


//import React, { useState } from 'react';
// import "../css/RegisterPage.css";
// import BackgroundImage from "../images/register-background.jpg";
// import LogoMedium from "../images/Logo_Medium.webp";
// import { useNavigate } from "react-router-dom";
// import GoogleAuthButton from "../components/GoogleAuthButton";
// import { setDoc, doc } from "firebase/firestore";
// import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
// import { firestore } from "../util/Firebase";
// import { FOOTER_CONFIG } from "../constants/FooterConfig";
//
// export const RegisterPage: React.FC<any> = ({ isLogin }) => {
//
//
//
//     return (
//         <div className="register-page-container">
//             <img
//                 className={"login-header-logo"}
//                 src={LogoMedium}
//                 alt={"Beyond Code Logo"}
//                 onClick={() => navigate("/")}
//             />
//             <div className="form-container">

//                 <p className="login-text">
//                     Already have an account? <a href="/login">Log in</a>
//                 </p>

//             </div>
//             <div className="image-container">
//                 <img src={BackgroundImage} alt="Computer Image" />
//             </div>
//         </div>
//     );
// };