import React from "react";
import ResumeImage from "../images/courses/How to Write a Programming Resume.webp"
import TechInterviewImage from "../images/courses/Interview Prep (Technical).webp"
import "../css/EmailSignUpPage.css"
import {useParams} from "react-router-dom";
import {DisplayBars} from "../components/DisplayBars";
import {HeroReviews} from "../components/HeroImage";

const SignUpPages = [
    {
        nav: "programmer-resume-guide",
        title: `What’s worse than failing a Tech Interview?<br/>Not landing one in the first place`,
        continuedText: 'How to Improve Your Tech Resume and Land More Interviews',
        image: ResumeImage,
        alt: 'Resume Guide Image',
        linkSource: "https://embeds.beehiiv.com/1185407e-a625-4bf6-8923-c5c57426e182?slim=true"
    },
    {
        nav: "tech-interview-guide",
        title: `Your Secret Weapon<br/>to Acing Tech Interviews`,
        continuedText: 'Coding Interview Study Guide',
        image: TechInterviewImage,
        alt: 'Coding Interview Study Guide Image',
        linkSource: "https://embeds.beehiiv.com/ef21bb78-3445-4baf-bf87-3cf848d13490?slim=true"
    }
]

function getSignUpPage(type: string): any {
    return SignUpPages.find((signUpPage) => signUpPage.nav === type) || null;
}

export const EmailSignUpPage: React.FC<any> = () => {
    const {type} = useParams();
    const signUpPage = getSignUpPage(type || "");
    if (!signUpPage) {
        return <></>;
    }

    return (
        <div className="email-sign-up-page-container">
            <DisplayBars position={'top'}/>
            <DisplayBars position={'bottom'}/>
            <div className="email-sign-up-page-content">
                <div className="email-sign-up-text-container">
                    <h1 dangerouslySetInnerHTML={{__html: signUpPage.title}}/>
                    <h2>Subscribe and receive your free guide: "{signUpPage.continuedText}"</h2>
                    <iframe src={signUpPage.linkSource}
                            data-test-id="beehiiv-embed" height="52" frameBorder="0" scrolling="no" className={'bee-hive-sign-up'}></iframe>
                    <div className="email-sign-up-under-form-container">
                        <HeroReviews/>
                        <p className="email-sign-up-page-privacy-text">We’ll never share your info with anyone.</p>
                    </div>
                </div>
                <div className={'email-sign-up-image-container'}>
                    <img alt={signUpPage.alt} src={signUpPage.image}/>
                </div>
            </div>
        </div>
    );
};