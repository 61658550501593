import React from "react";
import "../css/MembershipOverviewModule.css";
import {MEMBERSHIP_OVERVIEW_ITEMS} from "../constants/MembershipOverviewConfig";

export const MembershipOverviewModule = () => {
    return (
        <div className={'membership-overview-module-container'}>
            <h3>So... What is Beyond Code?</h3>
            {/*<div className={'membership-overview-subtitle'}>We help out developers, but we don't focus on code</div>*/}
            <div className={'membership-overview-subtitle'}>We help developers, but not with code</div>
            <div className={'membership-overview-subtitle'}>Instead, we help with.. well.. everything else</div>
            {/*<h4>Made for any developer, in any specialty</h4>*/}
            {/*<h4>We do this with the following:</h4>*/}
            <div className="membership-overview-module-content">
                {MEMBERSHIP_OVERVIEW_ITEMS.map((item, index) => (
                    <div key={index} className={`overview-item`}>
                        <div className="overview-icon">
                            <img src={item.icon} alt={`${item.header} icon`}/>
                        </div>
                        <h4>{item.header}</h4>
                    </div>
                ))}
            </div>
        </div>
    );
};